<template>

  <section id="videos-category">

    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          <font-awesome-icon 
            icon="border-all" 
            class="color-secondary pe-1"
          />
          Revisa o crea categorías para tus videos.
        </h3>
  
        <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
          <div class="card-body d-flex flex-column mh-700">
            <div class="row d-flex align-items-center my-3">
              <div class="col-12 col-lg-6">
                <h5 class="font-main text-secondary">
                  Lista de categorias creadas
                </h5>
              </div>
              <div class="col-12 col-lg-6">
                <div class="input-group">
                  <input 
                    type="text" 
                    class="form-control input-custom" 
                    maxlength="30"
                    v-model="name"
                    @keyup.enter="name.length > 0 && addCategorie"
                    placeholder="Escribe un nombre para crear una nueva categoría"
                  >
                  <button 
                    @click="addCategorie()"
                    class="btn btn-custom-color-white border" 
                    type="button">
                    <font-awesome-icon 
                      icon="plus" 
                      class="color-secondary"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <CategoryTable 
                  :categories_list="categories_list" 
                  @spinerClick="spinnerSwitch"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  
    <Spinner v-if="show_spinner" />
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />

<!--     <div class="video-add">
      <div class="video-add__container mt-5">
        <div class="video-category__title-wrapper">
          <p class="text-start">Lista de categorias creadas</p>
          <div class="video-category__add-input">
            <div class="video-category__input-container">
              <input
                class="video-category__input"
                type="text"
                placeholder="Escribe un nombre para crear una nueva categoría"
                maxlength="30"
                v-model="name"
                @keyup.enter="name.length > 0 && addCategorie"
              />
              <font-awesome-icon
                class="video-category__icon"
                icon="plus-circle"
                @click="addCategorie"
                style="cursor: pointer"
              />
            </div>
          </div>
        </div>
        <div class="video-category__table-wrapper">
          <CategoryTable :categories_list="categories_list" @spinerClick="spinnerSwitch"/>
        </div>
      </div>
      <Spinner v-if="show_spinner" />
      <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="modal_status"
        @close="closeStatus"
      />
    </div> -->

  </section>

</template>

<script>
import CategoryTable from "./CategoryTable.vue";
import Spinner from "../Spinner.vue";
import Status from "../Modales/Status.vue";

import { VIDEOS_CATEGORIAS } from "@/apollo/queries";
import {
  VIDEO_CAT_ADD
} from "@/apollo/mutations";
import { mapActions, mapState } from "vuex";

export default {
  components: { CategoryTable, Spinner, Status },
  data() {
    return {
      show_spinner: false,
      id_usuario: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,

      name: "",
      categories_list: [
      ],
    };
  },
  mounted() {
    this.getCategories();
    this.wallList(this.video_categories_actions_list);
  },
  computed: {
    ...mapState("videoCatModule", ["video_categories_actions_list", "video_categories_action"]),
    actions_list() {
      return this.$store.state.videoCatModule.video_categories_actions_list.length;
    },
  },
  watch: {
    actions_list() {
      setTimeout(() => {
        this.show_spinner = false;
      }, 500);
      this.wallList(this.video_categories_actions_list);
    },
  },
  methods: {
    ...mapActions("videoCatModule", ["addVideoCatAction", "addVideoCatAllAction"]),
    spinnerSwitch(new_state){
      this.show_spinner = new_state;
    },
    closeStatus() {
      this.open_modal_status = false;
    },
    async getCategories() {
      this.show_spinner = true;
      let query = {
        idEmpresa: this.id_empresa,
        plataforma: "2",
      };
      await this.$apollo
        .query({
          query: VIDEOS_CATEGORIAS,
          variables: query,
        })
        .then((res) => {
          var resp = res.data.categoriasVideos;
          this.categories_list = resp;
          this.show_spinner = false;
          this.wallList(this.video_categories_actions_list);
        })
        .catch(() => {
          this.show_spinner = false;
        });
    },
    async addCategorie() {
      this.show_spinner = true;
      let query = {
        idEmpresa: this.id_empresa,
        idUsuario: this.id_usuario,
        nombre: this.name,
      };
      await this.$apollo
        .mutate({
          mutation: VIDEO_CAT_ADD,
          variables: query,
        })
        .then((res) => {
          let new_cat = res.data.agregarCategoriaVideo;
          console.log(new_cat);
          this.addVideoCatAction({ action: "add", data: new_cat });
          this.show_spinner = false;
          this.$toast.open({
            message: "Categoría añadida con éxito.",
            type: "success",
            duration: 5000,
            position: "top-right",
          });
        })
        .catch(() => {
          this.$toast.open({
              message:
                "La categoría no ha podido ser añadida.",
              type: "error",
              duration: 5000,
              position: "top-right",
            });
          this.show_spinner = false;
        });
    },
    wallList(arr) {
      //console.log(this.wall_list)
      for (let i = 0; i < arr.length; i++) {
        const e = arr[i];
        switch (e.action) {
          case "delete":
            try {
              this.categories_list = this.categories_list.filter(
                (li) => li.id_categoria != e.data
              );
            } catch (error) {
              if (error) {
                console.log("err");
              }
            }
            break;
          case "add":
            try {
              this.categories_list = this.categories_list.filter(
                (li) => li.id_categoria != e.data.id_categoria
              );
            } catch (error) {
              if (error) {
                console.log("err");
              }
            }
            this.categories_list.unshift(e.data);
            break;
          case "edit":
            try {
              let is_in = this.categories_list.filter(
                (li) => li.id_categoria == e.data.id_categoria
              );
              if(is_in.length > 0){
                this.categories_list = this.categories_list.filter(
                (li) => li.id_categoria != e.data.id_categoria
                );
                this.categories_list.unshift(e.data);
              }
            } catch (error) {
              if (error) {
                console.log("err");
              }
            }
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
